.hero__content h2 {
  font-weight: 600;
  line-height: 55px;
}

.hero__content {
  /* background-image: url("../assets/img/banner1_3d.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  position: relative; */
  padding-top: 110px;
}
.hero__content h1 {
  font-size: 40px;
}
.hero__content p {
  font-size: 18px;
  padding-right: 60px;
  color: #717171;
}
.search input {
  border: none;
  outline: none;
  padding: 7px 10%;
}

.search_tutor_p {
  background-color: #e6e6ef;
  height: 110px !important;
  border-radius: 10px;
}
.search {
  width: 60%;
  border: 1px solid #17bf9d44;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  margin-top: 20px;
}

.tutor_box {
  max-height: 410px !important;
  min-height: 330px;
}

.tutor_price {
  bottom: 15px;
  position: absolute;
  width: -webkit-fill-available;
  right: 15px;
  left: 25px;
}
.product-img img {
  height: 140px;
  border-radius: 15px 15px 0 0;
  padding: 20px 0 10px 0px;
}

.bgHero {
  background: url("../../../assets/images/dashboard/bg.jpg");
  height: 70vh;
}
.br__1 {
  height: 400px !important;
}

.free_reg {
  font-size: 20px;
  border-radius: 10px;
}

.bg_img_1 {
  display: none;
}

.bg_img_2 {
  position: absolute;
  right: 100px;
  top: 8px;
  width: 50%;
  padding-top: 65px;
}
@media only screen and (max-width: 768px) {
  .hero__content {
    /* background-image: url("../assets/img/footer_plant_3d@2x.png");
    background-repeat: no-repeat;
    background-position: right bottom;
    position: relative; */
    padding-top: 80px;
  }
  .col_hero {
    padding-left: 0px;
    padding-right: 0px;
  }
  .bg_img_1 {
    display: none;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100px;
    bottom: 0;
    left: 0;
  }
  .bg_img_2 {
    position: absolute;
    top: auto;
    bottom: -30px;
  }
  .hero__content h1 {
    font-size: 23px;
    text-align: center;
  }
  .knowledgebase-bg {
    display: table-cell !important;
  }
  .search_t1 {
    padding: 20px 0 20px 0px;
    text-align: center;
  }
  .search_btn_public {
    place-content: center;
  }

  .search_tutor_p {
    place-content: center;
    height: 233px !important;
  }

  .hero__content p {
    font-size: 18px;
    text-align: center;
    padding-right: 10px !important;
  }
  .hero__content button {
    padding: 5px 15px 5px 15px !important;
    margin-bottom: 15px !important;
  }
  .get_started {
    text-align-last: center !important;
    margin-bottom: 30px;
  }
  .free_reg span {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .bg_img_2 {
    position: absolute;
    top: 0px;
    right: 46px;
    bottom: 0;
    width: 51%;
    padding-top: 65px;
  }
  .search_tutor_p {
    height: 95px;
  }
  .col-md-3 {
    flex: 0 0 auto !important;
    width: 25% !important;
  }
}
