/*! -----------------------------------------------------------------------------------

    Template Name: Zeta Admin
    Template URI: http://admin.pixelstrap.com/zeta/theme
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------
 */
// *** utils ***
:root {
  --theme-default: #6362e7;
  --theme-secondary: #ffc500;
}
@import "utils/variables";
// *** base ***
@import "base/reset";
@import "base/typography";
//  *** components ***
@import "components/according";
@import "components/alert";
@import "components/avatars";
@import "components/badge";
@import "components/bookmark";
@import "components/breadcrumb";
@import "components/buttons";
@import "components/card";
@import "components/dropdown";
@import "components/form-input";
@import "components/forms";
@import "components/icons";
@import "components/list";
@import "components/loader";
@import "components/popover";
@import "components/print";
@import "components/radio";
@import "components/ribbon";
@import "components/switch";
@import "components/select2";
@import "components/tab";
@import "components/table";
@import "components/touchspin";
@import "components/typeahead-search";
@import "components/scrollbar";
@import "components/react-plugin";
//	*** pages ***
@import "pages/blog";
@import "pages/bookmark-app";
@import "pages/cart";
@import "pages/chart";
@import "pages/chat";
@import "pages/checkout";
@import "pages/comingsoon";
@import "pages/contacts";
@import "pages/dashboard_2";
@import "pages/dashboard_default";
@import "pages/crypto-dashboard";
@import "pages/ecommerce";
@import "pages/email-application";
@import "pages/errorpage";
@import "pages/faq";
@import "pages/file";
@import "pages/gallery";
@import "pages/job-search";
@import "pages/kanban";
@import "pages/knowledgebase";
@import "pages/learning";
@import "pages/login";
@import "pages/megaoption";
@import "pages/order-history";
@import "pages/pricing";
@import "pages/progress";
@import "pages/projectlist";
@import "pages/social-app";
@import "pages/search";
@import "pages/task";
@import "pages/invoice";
@import "pages/user-profile";
@import "pages/steps";
/* ---------------------
	*** themes ***
-----------------------*/
@import "themes/_dark";
@import "themes/theme-customizer";
@import "themes/update";
// *** layout ***
@import "layout/footer";
@import "layout/grid";
@import "layout/header";
@import "layout/navs";
@import "layout/sidebar";
@import "layout/rtl";
@import "layout/box-layout";