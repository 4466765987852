.default_class,
.default_class .rbc-event-content {
  background-color: var(--theme-default) !important;
}

.default_class,
.default_class .rbc-event-label {
  background-color: var(--theme-default) !important;
  color: #ffffff !important;
}

.paid_class,
.paid_class .rbc-event-content {
  background-color: darkgreen !important;
  color: #ffffff !important;
}

.paid_class,
.paid_class .rbc-event-label {
  background-color: darkgreen !important;
  color: #ffffff !important;
}

.complete_class,
.complete_class .rbc-event-content {
  background-color: darkorchid !important;
  color: #ffffff !important;
}

.complete_class,
.complete_class .rbc-event-label {
  background-color: darkorchid !important;
  color: #ffffff !important;
}
