.single__feature h2 i {
  font-size: 2.5rem;
}

.single__feature h6 {
  font-weight: 600;
}

.features_1 {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  #features {
    padding-top: 0px !important;
  }
  .features_1 {
    padding-top: 10px;
  }
}
