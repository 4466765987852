.tutorViewResponsive {
  display: flex !important;
}

.filterListResponsive {
  margin-top: 38px !important;
}

@media only screen and (max-width: 770px) {
  .tutorViewResponsive {
    flex-direction: column-reverse !important;
  }
}

.col-md-3 {
  flex: 0 0 auto !important;
}

.listViewLink a:link {
  color: blue !important;
  background-color: transparent !important;
  text-decoration: none !important;
}

.listViewLink a:visited {
  color: red !important;
  background-color: transparent !important;
  text-decoration: none !important;
}

.listViewLink a:hover {
  color: green !important;
  background-color: transparent !important;
  text-decoration: underline !important;
}

.listViewLink a:active {
  color: greenyellow !important;
  background-color: transparent !important;
  text-decoration: underline !important;
}

.icon_visited {
  background: #63709154 !important;
}

.single_tutor {
  background: #f7f8f8;
  border-radius: 10px;
}

.single_tutor img {
  border-radius: 25px;
  height: 40px;
}

.search_tutor .form-control {
  height: 35px;
}

.tutor_name .new_tab_icon {
  visibility: hidden;
}

.tutor_name:hover .new_tab_icon {
  visibility: visible;
}

.info-block:hover {
  background-color: #97a0d829;
}

.search_tutor {
  justify-content: center;
  border: 1px solid #872d840d;
  padding: 12px;
  border-radius: 10px;
  background-color: #ece5f35e;
}

.filter_card_t {
  padding: 10px;
  border-radius: 10px;
  background: #f7f8f8;
  margin-bottom: 10px;
}

.filter_card {
  padding: 10px;
  border-radius: 10px;
  background: #f7f8f8;
  margin-bottom: 10px;
}

.f_title {
  margin-bottom: 7px;
}

.f_title span {
  border-bottom: 1px solid #e5cfcf;
  padding-bottom: 5px;
}

@media only screen and (max-width: 576px) {
  .search_title {
    padding-top: 15px;
  }
}

.pagination {
  position: relative !important;
}

.paginationBttns {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #6362e7;
  color: #6362e7;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: #ffffff !important;
  background-color: #3131e4 !important;
}

.paginationActive a {
  color: #ffffff !important;
  background-color: #6362e7;
}

.paginationDisabled a {
  color: grey;
  background-color: grey;
}

@media only screen and (max-width: 991px) {
  .paginationBttns {
    width: 100%;
  }
}
.clickHere {
  cursor: pointer !important;
}

