.call-chat-sidebar .card-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.unseem-msg {
  background: #f2f0f7;
  border-radius: 5px;
}

.chat-box .people-list ul li {
  padding-bottom: 0px !important;
  margin-bottom: 3px !important;
}
.conversation_img {
  margin: 3px;
}

.chat_selected {
  border: 1px solid #c7c4da;
  border-radius: 5px;
  background-color: #d0d0d06b;
}
.chat_selected:hover {
}

li a:active {
  background-color: #d0d0d06b;
}

.chat-box .chat-right-aside .chat .chat-msg-box .message {
  margin-bottom: 10px !important;
  padding: 10px !important;
}

.chat-box .chat-right-aside .chat .chat-msg-box .my-message {
  background: #faebd74d;
}

.chat-box .chat-right-aside .chat .chat-msg-box .other-message {
  background: #e4f2ff7d;
}

.chat-box .chat-right-aside .chat .chat-msg-box {
  background: #b6b6b614;
}

.chat-box .chat-right-aside .chat .chat-msg-box .message-data-time {
  color: #82858a;
  font-size: 11px;
}

.chat_header_bg {
  background-color: #f9f9f9a3;
}

.deactivated {
  background: #e8e8e83b;
}
