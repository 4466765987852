// General variables
$theme-font-color: #242934;
$theme-body-font-color: #2b2b2b;
$theme-body-sub-title-color: rgba($theme-body-font-color, 0.54);
$body-color: #ffffff;
// Theme colors variables
$primary-color: #6362e7;
$secondary-color: #ffc500;
$success-color: #7dc006;
$info-color: #1d97ff;
$warning-color: #ff8819;
$danger-color: #e52727;
$light-1: #8f97b2;
$light-2: #f4f6fd;
$dark: #1e2f65;
$black: #000;
$white: #fff;
$light: #f4f6fd;
$light-color: #f4f4f4;
$light-gray: #ecf3fa;
$light-widget-border: #efeeff;
$light-semi-gray: #efefef;
$font-gray-color: #6c757d;
$dark-light: $theme-font-color;
$dark-gray: #898989;
$dark-color: #2c323f;
$gray-60: #cccccc;
$card-body-color: #f9f9fa;
$transparent-color: transparent;
$auth-bg-color: #fafafa;
$semi-dark: #59667a;
$light-text: #999999;
$light-paragraph: #8f8f8f;
$light-background: #d7e2e9;
$light-font: rgba(27, 49, 85, 0.4);
$light-semi-font: $semi-dark;
$light-chartist-grid-color: #f6f9fd;
$pitch-color: #fe8a7d;
$color-scarpa: #4c5667;
$color-fiord: #455a64;
$black: #000;
$white: #fff;
$off-white: #f7f6ff;
$theme-medium-color: #eae8ff;
$dropdown-border-color: #918e99;
// gradient colors
$gradient-primary: linear-gradient(
  26deg,
  var(--theme-default) 0%,
  #a26cf8 100%
);
// other table
$table-row-hover: #eaeaea;
$light-table-color: #f6f6f6;
//social colors
$fb: #50598e;
$twitter: #6fa2d8;
$insta: radial-gradient(
    circle farthest-corner at 35% 90%,
    #fec564,
    transparent 50%
  ),
  radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
  radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
  radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
  radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
  radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
  radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
  linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
$google-plus: #c64e40;
$linkedin: #0077b5;
//error input color start
$light-body-bg-color: #fafdff;
//fonts
$font-rubik: Rubik;
$font-roboto: "Roboto", sans-serif;
$font-serif: sans-serif;
$font-themify: themify;
$font-awesome: FontAwesome;
$font-ICO: IcoFont;
// dark layout variables
$dark-body-background: #1d1e26;
$dark-card-background: #262932;
$dark-card-inbox: #323846;
$dark-small-font-color: #98a6ad;
$dark-all-font-color: rgba(255, 255, 255, 0.6);
$light-all-font-color: rgba(255, 255, 255, 0.2);
$sidebar-submenu-font-color: rgba(255, 255, 255, 0.6);
$dark-border-color: $dark-body-background;
$dark-card-border: #374558;
$dark-editor-document: #2b2b2b;
$bg-dark-color: #1f2533;
$dark-datatable-odd: #1f232b;
$dark-datatable-sorting: #1c212b;
$dark-datatable-sorting-even: #22262f;
$dark-even-hover-sorting: #191e27;
//General tag settings
$body-font-size: 14px;
$body-font-color: $theme-body-font-color;
$ul-padding-left: 0px;
$ancher-color: var(--theme-default);
$btn-focus-box-shadow: none;
$all-focus-outline-color: var(--theme-default);
$paragraph-font-size: 13px;
$paragraph-line-height: 1.7;
$paragraph-letter-spacing: 0.7px;
$code-tag-color: var(--theme-default);
$code-tag-bg-color: rgba($primary-color, 0.03);
$code-tag-padding: 3px;
$code-tag-margin: 0 3px;
$code-tag-border-radious: 2px;
$blockquote-padding: 15px;
$blockquote-border: 4px solid $light-gray;
$small-tag-padding: 10px;
$small-tag-color: $dark-color;
$pre-tag-bg-color: rgba($primary-color, 0.03);
$pre-tag-padding: 20px 0;
$list-group-active-bg-color: var(--theme-default);
$list-group-active-border-color: var(--theme-default);
$list-group-img-size: 40px;
$list-group-margin: 10px;
// Tables settings
$table-b-margin: 0px;
$table-heading-color: $theme-body-font-color;
$table-row-color: $theme-body-font-color;
$table-footer-font-weight: bold;
$table-inverse-color: $white;
$table-hover-color: $light;
$table-padding: 0.75rem;
$table-xl-padding: 1.25rem 2rem;
$table-xl-font: 120%;
$table-lg-padding: 0.9rem 2rem;
$table-lg-font: 110%;
$table-de-padding: 0.75rem 2rem;
$table-de-font: 100%;
$table-sm-padding: 0.5rem 2rem;
$table-sm-font: 90%;
$table-xs-padding: 0.2rem 2rem;
$table-xs-font: 80%;
$table-border-color: #dee2e6;
$horizontal-border-color: #f2f4ff;
$horizontal-padding: 0.75rem 2rem;
$table-inverse-bg-color: #292b2c;
//According setting
$according-card-top-margin: 10px;
$according-btn-weight: 500;
$according-btn-color: $theme-body-font-color;
$according-card-header-padding: 0.75rem 1.25rem;
$according-card-header-icon: $font-ICO;
$according-open-icon: "\eb73";
$according-close-icon: "\eb7a";
//Alert settings
$alert-hover-color: $dark-color;
$alert-border-radious: 0.15rem;
$alert-padding: 15px;
$alert-msg-icon-size: 16px;
//badge settings
$badge-padding: 0.44em 0.7em;
$badge-svg-size: 15px;
//form input settings
$form-group-margin-bottom: 1.25em;
$col-form-label-font-size: 14px;
$form-control-font-size: 14px;
$form-control-border-radious: 2px;
$form-control-border-color: $light-gray;
//breadcrumb setting
$breadcrumb-ancher-color: $white;
$breadcrumb-ancher-dark-color: $black;
//buttons setting
$btn-font-size: 14px;
$btn-padding: 0.375rem 1.75rem;
$btn-lg-font-size: 18px;
$btn-sm-font-size: 12px;
$btn-xs-font-size: 11px;
$btn-xs-padding: 0.05rem 0.4rem;
//Card settings
$card-padding: 10px;
$card-margin-bottom: 30px;
$card-border-color: 1px solid $light-gray;
$card-border-radious: 15px;
$common-shadow-color: rgba($info-color, 0.08);
$card-box-shadow: 0px 4px 30px rgba(95, 94, 231, 0.07);
$card-header-bg-color: $white;
$card-header-font-transform: capitalize;
$card-header-span-size: 12px;
$card-header-span-color: $theme-body-sub-title-color;
$card-body-bg-color: $transparent-color;
$card-footer-bg-color: $white;
$card-hover-box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
//footer settings
$footer_bg_color: $white;
$footer_box_shadow: 0 0 20px rgba(95, 94, 231, 0.07);
$footer_dark_color: $theme-font-color;
$footer_dark__txt_color: $white;
//form settings
$form-group-margin-bottom: 20px;
$sm-form-margin-bottom: 14px;
$form-placeholder-color: $dark-gray;
$form-placeholder-font-size: 14px;
$form-placeholder-letter-spacing: 1px;
$form-placeholder-font-weight: 100;
$form-input-border-color: $light-semi-gray;
$form-input-bg-color: $white;
//Tour settings
$tour-color: var(--theme-default);
//sidabr main settings
$sidebar-width: 255px;
$sidebar-position: fixed;
$sidebar-background-color: $white;
$sidebar-shadow: 0 0 11px rgba(69, 110, 243, 0.13);
$sidebar-overflow: auto;
$sidebar-z-index: 9;
$sidebar-transition: 0.5s;
//sidebar profile settings
$sidebar-profile-edit-icon-size: 14px;
$sidebar-profile-name-txt-color: var(--theme-default);
$sidebar-profile-name-txt-transfer: uppercase;
$sidebar-profile-name-txt-weight: 600;
$sidebar-profile-name-letter-specing: 1.5px;
$sidebar-profile-name-marging: 3px;
$sidebar-profile-sub-title-font-size: 10px;
$sidebar-profile-sub-title-margin: 0px;
$sidebar-profile-img-shadow: 0 0 15px rgba(68, 102, 242, 0.3);
//Sidebar main menu setting
$sidebar-menu-padding: 15px;
$sidebar-menu-list-style: none;
$sidebar-menu-list-margin: 0;
$sidebar-icon-size: 14px;
$sidebar-icon-margin: 14px;
$sidebar-icon-stroke-width: 3px;
$sidebar-font-size: 14px;
$sidebar-letter-specing: 0.5px;
$sidebar-txt-transform: capitalize;
$sidebar-font-weight: 600;
$sidebar-font-color: $theme-body-font-color;
$sidebar-padding-top: 12px;
$sidebar-padding-bottom: 12px;
$sidebar-sub-header-padding: 15px;
$sidebar-sub-header-margin: 0;
$sidebar-heading-hover-padding: 5px;
$sidebar-hover-txt-color: var(--theme-default);
$sidebar-arrow-margin-top: 2px;
$sidebar-arrow-size: 15px;
$sidebar-arrow-color: $theme-body-font-color;
$sidebar-open-icon: "\f107";
$sidebar-close-icon: "\f105";
$sidebar-icon-font-family: $font-awesome;
$sidebar-height: calc(100vh - 115px);
$new-sidebar-height: calc(100vh - 400px);
//Header settings
$main-header-position: fixed;
$main-header-top: 0;
$main-header-shadow: 0 0 20px rgba($semi-dark, 0.1);
$main-header-shadow-1: 0px 4px 40px rgba(0, 0, 0, 0.07);
//page settings
$page-body-padding: 0 15px 0 15px;
$page-body-bg-color: $light-color;
$page-body-margin-bottom: 0px;
$page-title-padding: 30px 0px;
$common-box-shadow: 2px 3.464px 8px 0px rgba(22, 145, 248, 0.18);
$page-title-font-size: 25px;
$page-title-margin-bottom: 0;
$page-title-text-tranform: capitalize;
$page-small-title-display: block;
$page-small-title-font-size: 12px;
$page-small-title-margin-bottom: 5px;
$page-small-title-text-transform: capitalize;
$page-small-title-text-color: $theme-body-sub-title-color;
$breadcrumb-size: 16px;
$breadcrumb-content: "/";
//main header left settings
$header-left-bg-color: $white;
$main-header-padding: 27px 22px;
$main-header-z-index: 9;
$header-wrapper-padding: 13px 30px;
$header-wrapper-nav-right: 0px;
$header-wrapper-nav-icon-size: 20px;
$header-wrapper-nav-icon-color: $theme-font-color;
$header-wrapper-nav-icon-align: middle;
$header-size: 136px;
$box-layout-space: 40px;