.deactivate {
  vertical-align: middle;
  display: table-cell;
}

.tab_title {
  border-bottom: 1px solid #fbf0f0;
  padding-bottom: 6px;
}

.border_bottom {
  border-bottom: 1px solid #a09bc324;
  padding-bottom: 15px;
}

.bg_deactivated {
  background-color: #91abbc61;
}
.bg_delete {
  background-color: #ed6d6461;
}
.bg_hide {
  background-color: #ed9b4ad5;
}
.bg_visualize {
  background-color: #6ef49261;
}
