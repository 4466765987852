@media only screen and (max-width: 768px) {
  .faq-wrap h1 {
    font-size: 21px;
    text-align: center;
  }
  .faq-wrap h3 {
    font-size: 18px;
    text-align: center;
  }
  .faq-wrap h5 {
    font-size: 15px;
    text-align: center;
  }
  .faq-wrap h6 {
    font-size: 15px;
  }
}
