.unseen-chat {
  font-weight: 800;
}
.bgUnseen {
  background: #bbbbbb38 !important;
  margin-bottom: 1px;
}
.header_notification h6 {
  font-size: 14px;
  margin-bottom: 0px;
}
.header_notification .notification-img {
  padding-top: 0px !important;
}
