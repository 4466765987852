@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap");

.footer {
  background: rgba(226, 248, 248, 0.664) !important;
  margin-left: 0px !important;
  font-family: "Titillium Web", sans-serif;
}

.follows span a {
  text-decoration: none;
}

.follows span {
  margin-right: 15px;
}

.link__list .link__item {
  background: transparent;
}

.link__list a {
  text-decoration: none;
  color: #0a2b1e;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .footer h2 {
    place-content: center;
  }
  .footer__1 {
    text-align: -webkit-center;
  }
}

.icon:hover {
  transform: scale(1.2);
  transition: transform 0.2s;
}
