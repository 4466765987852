.delete-button {
  margin-bottom: 4px;
}

.mapboxgl-map {
  margin-bottom: 10px;
}

.mapboxgl-ctrl-geocoder {
  border: 1.5px solid black;
  width: 400px;
}
@media only screen and (max-width: 500px) {
  .mapboxgl-ctrl-geocoder {
    width: 200px !important;
  }
}

.align-right {
  text-align: right;
}

.asterisk::after {
  content: "*";
  color: red;
}
.inputFieldStyle {
  padding: 8.5px !important;
}

@media only screen and (max-width: 768px) {
  .topSpace {
    margin-top: 26px !important;
  }
}
@media only screen and (max-width: 370px) {
  .topSpace {
    margin-top: 42x !important;
  }
}
.hideAccount::after {
  content: "Account is hide now";

  position: absolute !important;

  display: none !important;
}

.hideAccount:hover::after {
  display: block !important;
}

.visibleAccount::after {
  content: "Account is visible now";

  position: absolute !important;

  display: none !important;
}

.visibleAccount:hover::after {
  display: block !important;
}
